import Header from "./components/Header";
import Main from "./components/Main";
  
function App() {
  return (
    <>
      <Header />
      <Main />
      </>
  );
}

export default App;
